import EXIF from 'exif-js'

export const loadExifData = (file: File) => {
  return new Promise((resolve) => {
    // @ts-ignore
    EXIF.getData(file, function () {
      // @ts-ignore
      const exif = EXIF.pretty(this)
      // @ts-ignore
      return resolve(exif)
    })
  })
}

export const getBlob = async (fileUri: string) => {
  const resp = await fetch(fileUri)
  return await resp.blob()
}

export const isHTML = (str: string) => {
  return /<[a-z][\s\S]*>/i.test(str)
}
